import { Button, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { SiMicrosoftexcel } from 'react-icons/si';

type THeader = {
  months: { label: string, value: string }[],
  month: string | undefined,
  onMonthChange: (month: string) => void
}

const header = [
  { label: '근무처', key: 'schoolName' },
  { label: '과목명', key: 'className' },
  { label: '강사명', key: 'teacherName' },
  { label: '지급계', key: 'totalPay' },
  { label: '경비율제외', key: 'excludeExpense' },
  { label: '특고료', key: 'manuscript' },
  { label: '산재보험료', key: 'wci' },
  { label: '소득세', key: 'incomeTax' },
  { label: '주민세', key: 'residentTax' },
  { label: '실지급액', key: 'netAmount' }
];

const Header = ({ month, months, onMonthChange }: THeader) => {
  const [excelData, setExcelData] = useState([]);

  const csvLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  useEffect(() => {
    if (excelData.length) {
      csvLink.current?.link.click();
    }
  }, [excelData])

  const onDownload = () => {
    const list = document.querySelectorAll('tbody > tr');
    const targetData = Array.from(list).reduce((acc: any, v, i) => {
      let item = {}
      v.childNodes.forEach((td: any, idx: number) => {
        if (idx === 0) {
          item = { schoolName: td.innerText }
        }
        if (idx === 1) {
          item = { ...item, className: td.innerText }
        }
        if (idx === 2) {
          item = { ...item, teacherName: td.childNodes[0].innerText }
        }
        if (idx === 33) {
          item = { ...item, totalPay: td.innerText }
        }
        if (idx === 34) {
          item = { ...item, excludeExpense: td.innerText }
        }
        if (idx === 35) {
          item = { ...item, manuscript: td.innerText }
        }
        if (idx === 36) {
          item = { ...item, wci: td.innerText }
        }
        if (idx === 37) {
          item = { ...item, incomeTax: td.innerText }
        }
        if (idx === 38) {
          item = { ...item, residentTax: td.innerText }
        }
        if (idx === 39) {
          item = { ...item, netAmount: td.innerText }
        }
      });
      acc.push(item);
      return acc;
    }, []);
    setExcelData(targetData);
  }
  
  return (
    <header id='header'>
      <h1>
        급여지급대장
        <Select style={{ width: 120 }} onChange={onMonthChange} options={months} value={month} />
        <Button type='primary' onClick={onDownload}>
          <SiMicrosoftexcel />
        </Button>
        <CSVLink ref={csvLink} data={excelData} filename={`급여지급대장_${month?.replace('-', '')}`} 
          headers={header} target='_blank' />
      </h1>
    </header>
  );
};

export default Header;