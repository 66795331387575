import { useEffect, useState } from 'react';
import { HttpUtil } from '@utils';

type ResponseType = { [key: string]: any }

const useFetch = (params: any) => {
  const [requestParams, setRequestParams] = useState<any>(params);
  const [isFetching, setIsFetching] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState<ResponseType | null>(null);
  
  useEffect(() => {
    const getData = async () => {
      if (!requestParams) {
        return;
      }
      const res= await HttpUtil.requestApi(requestParams);
      setIsFetching(false);
      setIsLoading(false);
      const { data, msg } = res;
      const { method, showResult } = requestParams;
      const showResultMsg = showResult ?? true;
      
      if (msg) {
        window.alert(msg);
      } else {
        if (showResultMsg) {
          if (method === 'POST' || method === 'PUT') {
            window.alert('저장되었습니다.');
          }
          if (method === 'DELETE') {
            window.alert('삭제되었습니다.');
          }
        }
      }
      setApiResponse(msg ? null : data);
    }
    getData();
  }, [requestParams]);

  const apiRequest = (args: any) => {
    setIsLoading(args.loading || false);
    setIsFetching(args.fetching || false);
    setRequestParams(args);
  }

  return { apiResponse, isFetching, isLoading, apiRequest };
}

export default useFetch;