class StorageUtil {
  getItem = (itemName: string, isLocal: boolean = true) => {
    const storage = isLocal ? localStorage.getItem(itemName) : sessionStorage.getItem(itemName);
    return storage ? (JSON.parse(storage)).data : null;
  }

  setItem = (itemName: string, data: any, isLocal: boolean = true) => {
    if (isLocal)
      localStorage.setItem(itemName, JSON.stringify(data));
    else
      sessionStorage.setItem(itemName, JSON.stringify(data));
  }

  removeItem = (itemName: string, isLocal: boolean = true) => {
    if (isLocal)
      localStorage.removeItem(itemName);
    else
      sessionStorage.removeItem(itemName);
  }
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new StorageUtil();