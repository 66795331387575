import { Form, Input } from 'antd';
import { ReactNode } from 'react';

type TFormInput = {
  name: string,
  label?: string,
  placeholder?: string,
  required?: boolean,
  disabled?: boolean,
  className?: string,
  child?: ReactNode
}

const FormInput = ({ name, label, placeholder, required = true, disabled = false, className = '', child }: TFormInput) => {
  return (
    <>
      <Form.Item name={name} label={label ?? ''} rules={[{ required }]} className={className}>
        <Input placeholder={placeholder ?? ''} disabled={disabled} />
      </Form.Item>
      {child}
    </>
  );
}

export default FormInput;