import { Form, Select } from 'antd';

type TFormSelect = {
  name: string,
  label: string,
  placeholder?: string,
  disabled?: boolean,
  required?: boolean,
  list: { name: string, value: string | number }[],
  onChange?: (item: string) => void,
  className?: string
}

const { Option } = Select;

const FormSelect = ({ name, label, placeholder, required, list, disabled, onChange, className = '' }: TFormSelect) => {
  return (
    <Form.Item name={name} label={label} rules={[{ required: required ?? true }]} className={className}>
      <Select placeholder={placeholder ?? ''} onChange={onChange} disabled={disabled ?? false}>
        {list.map((v, i) => <Option key={i} value={v.value}>{v.name}</Option>)}
      </Select>
    </Form.Item>
  );
}

export default FormSelect;