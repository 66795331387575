import { Button } from 'antd';

const FormSubmit = ({ name }: { name: string }) => {
  return (
    <Button type='primary' htmlType='submit' className='btn-submit'>
      {name}
    </Button>
  );
}

export default FormSubmit;