import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Login, Main, PaySlip } from './pages';
import '@css/font.scss';
import '@css/common.scss';

const App = () => {
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/paySlip') {
      const storage = sessionStorage.getItem('isLogin');
      const savedIsLogin = storage === null ? false : JSON.parse(storage).data;
      setIsLogin(savedIsLogin);
      if (!savedIsLogin) {
        navigate('/login', { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [navigate]);

  return (
    <div id='wrapper-main'>
      <Routes>
        <Route path='/' element={isLogin ? <Main /> : <></>} />
        <Route path='/login' element={<Login />} />
        <Route path='/paySlip' element={<PaySlip />} />
      </Routes>
    </div>
  );
}

export default App;