import { useEffect, useState } from 'react';
import { Button, Checkbox, DatePicker, Divider, Form, Modal, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useFetch } from '@hooks';
import { DataList, Header } from '@pages';
import { FormCheckbox, FormInput, FormSelect } from '@components';
import { HttpUtil, StorageUtil } from '@utils';
import dayjs from 'dayjs';
import '@css/main.scss';

type TSchool = { 
  name: string, 
  regDate: string 
}

type TMember = {
  no: number,
  className: string,
  schoolName: string,
  teacherName: string,
  teacherId: string,
  commission4w: number,
  headCount4w: number,
  headCount11of12: number,
  headCount10of12: number,
  headCount7of8: number,
  headCount3w: number,
  headCount5of8: number,
  headCount2w: number,
  headCount3of8: number,
  headCount1w: number,
  headCount2of3: number,
  headCount1of3: number,
  bookCost: number,
  isCodi: number,
  targetMonth: string
}

const defParams = { 
  url: '/main', 
  method: 'GET', 
  params: { 
    reqType: 'main', 
    targetMonth: dayjs().format('YYYY-MM'),
    targetSchool: ''
  } 
}

const Main = () => {
  const [member, setMember] = useState<TMember[]>([]);
  const [detail, setDetail] = useState<TMember>();
  const [school, setSchool] = useState<{ name: string, value: string }[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [currSchool, setCurrSchool] = useState('');
  const [currMonth, setCurrMonth] = useState<string | undefined>(undefined);
  const [payMonth, setPayMonth] = useState(dayjs(dayjs(), 'YYYY-MM'));
  const [showAll, setShowAll] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState('');
  const [method, setMethod] = useState('POST');
  const [months, setMonths] = useState<{ label: string, value: string }[]>([{ label: '로드중', value: '로드중' }]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [form] = useForm();

  const { apiResponse, isFetching, apiRequest } = useFetch(defParams);

  useEffect(() => {
    if (!apiResponse) {
      return;
    }
    const { school, member, months } = apiResponse;
    setMember(member);
    setSchool(school.map((v: TSchool) => ({ name: v.name + '초', value: v.name })));
    setMonths(months.map((v: string) => ({ label: v, value: v })));
    setCurrMonth(apiResponse.payMonth);
    setTotalCount(member?.length || 0);
    setShowAll(StorageUtil.getItem('showAll'));
  }, [apiResponse]);

  useEffect(() => {
    Array.from(document.querySelectorAll('.hidable')).forEach(v => {
      v.classList[showAll ? 'remove' : 'add']('hide');
    });
  }, [showAll]);

  const onFinish = async (values: any) => {
    const { payMonth } = values;
    const month = payMonth.$M + 1;
    const params = {
      no: detail?.no || 0,
      isCodi: values.isCodi?.length ? 1 : 0,
      schoolName: values.schoolName,
      className: values.className,
      teacherName: values.teacherName,
      teacherId: values.teacherId,
      commission4w: values.commission4w,
      headCount4w: values.headCount4w ?? 0,
      headCount11of12: values.headCount11of12 ?? 0,
      headCount10of12: values.headCount10of12 ?? 0,
      headCount7of8: values.headCount7of8 ?? 0,
      headCount3w: values.headCount3w ?? 0,
      headCount5of8: values.headCount5of8 ?? 0,
      headCount2w: values.headCount2w ?? 0,
      headCount3of8: values.headCount3of8w ?? 0,
      headCount1w: values.headCount1w ?? 0,
      headCount2of3: values.headCount2of3 ?? 0,
      headCount1of3: values.headCount1of3 ?? 0,
      bookCost: values.bookCost ?? 0,
      targetMonth: payMonth.$y + '-' + (month < 10 ? '0' + month : month),
      targetSchool: selectedSchool
    }

    apiRequest({ url: '/main', method, params, isFetching: true, schoolName: selectedSchool });
    setCurrSchool(values.schoolName);
    setPayMonth(payMonth);
    setShowModal(false);
    form.resetFields();
  }

  const onOk = async () => {
    try {
      await form.validateFields(['payMonth', 'className', 'teacherName', 'commission4w']);
      onFinish(form.getFieldsValue());
    } catch (e) {
      console.error(e);
    }
  }

  const onCancel = () => {
    setShowModal(false);
    setTimeout(() => {
      form.resetFields();
    }, 150);
  }

  const onAddTeacherClick = () => {
    setMethod('POST');
    setShowModal(true);
    form.setFieldsValue({
      schoolName: currSchool || school[0].value,
      payMonth
    });
  }

  const onMonthChange = (month: string) => {
    setCurrMonth(dayjs(month).format('YYYY-MM'));
    apiRequest({ ...defParams, params: { reqType: 'main', targetMonth: month } });
  }

  const onNameClick = (no: number) => {
    const t = member.filter(v => v.no === no)[0];
    form.setFieldsValue({
      className: t.className,
      schoolName: t.schoolName,
      teacherName: t.teacherName,
      teacherId: t.teacherId,
      payMonth: dayjs(t.targetMonth, 'YYYY-MM'),
      commission4w: t.commission4w,
      headCount4w: t.headCount4w || '',
      headCount11of12: t.headCount11of12 || '',
      headCount10of12: t.headCount10of12 || '',
      headCount7of8: t.headCount7of8 || '',
      headCount3w: t.headCount3w || '',
      headCount5of8: t.headCount5of8 || '',
      headCount2w: t.headCount2w || '',
      headCount3of8: t.headCount3of8 || '',
      headCount1w: t.headCount1w || '',
      headCount2of3: t.headCount2of3 || '',
      headCount1of3: t.headCount1of3 || '',
      bookCost: t.bookCost || ''
    });
    setDetail(t);
    setMethod('PUT');
    setShowModal(true);
    if (t.isCodi > 0) {
      form.setFieldValue('isCodi', ['codi']);
    }
  }

  const onDelete = () => {
    if (!window.confirm('삭제하시겠습니까?') || !detail) {
      return;
    }
    apiRequest({
      url: '/main',
      method: 'DELETE',
      params: {
        teacherName: detail.teacherName,
        className: detail.className,
        schoolName: detail.schoolName,
        targetMonth: detail.targetMonth,
        targetSchool: selectedSchool
      }
    });
    setShowModal(false);
    setTimeout(() => {
      form.resetFields();
    }, 150);
  }

  const modalFooter = () => [
    method === 'PUT' && <Button key='0' onClick={onDelete}>삭제</Button>,
    <Button key='1' onClick={onCancel}>
      취소
    </Button>,
    <Button key='2' type='primary' onClick={onOk} loading={isFetching}>
      저장
    </Button>,
  ];

  const onShowAllChange = () => {
    StorageUtil.setItem('showAll', { data: !showAll }, true);
    setShowAll(prev => !prev);
  }

  const onSchoolChange = (value: string) => {
    setSelectedSchool(value);
    apiRequest({ url: '/main', method: 'GET', params: { reqType: 'main', targetSchool: value, targetMonth: currMonth } });
  }

  const onCopyClick = () => {
    if (!window.confirm('현재 데이터를 월만 내달로 바꿔 동일하게 복사합니다. 계속하시겠습니까?')) {
      return;
    }
    const month = dayjs(currMonth).format('YYYY-MM');
    const nextMonth = dayjs(currMonth).add(1, 'month').format('YYYY-MM');
    apiRequest({ 
      ...defParams, 
      method: 'POST', 
      params: { reqType: 'copy', currMonth: month, nextMonth, targetMonth: nextMonth } 
    });
  }

  const handleTotalAmount = (v: number) => {
    setTotalAmount(v);
  }

  const getValueWithComma = (v: number) => {
    return !v ? v : v.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  }

  const onSendSalaryClick = async () => {
    const getAmount = (v: Element, className: string) => {
      return Number(v.querySelector(`.${className}`)?.innerHTML.replaceAll(',', ''));
    }
    if (!member.length) {
      window.alert('대상 교직원이 존재하지 않습니다.');
      return;
    }
    if (selectedSchool) {
      window.alert('지급명세서는 일괄 작업으로 전체 목록일 때 전송할 수 있습니다.');
      return;
    }
    if (!window.confirm(`${currMonth?.split('-')[1]}월 전체 급여내역을 저장합니다. 계속하시겠습니까?\n기존 데이터가 있는 경우 덮어쓰기 됩니다.`)) {
      return;
    }

    const nodes = document.querySelectorAll('.each-member');
    const members = Array.from(nodes).reduce<any>((acc, v) => {
      const name = v.querySelector('.name')?.innerHTML;
      const totalPay = getAmount(v, 'total-pay');

      // 수강인원
      const headcountElements = v.querySelectorAll('.headcount');
      const headcount = Array.from(headcountElements).reduce((acc2, v2) => {
        acc2 += +v2.innerHTML;
        return acc2;
      }, 0);

      // 공제
      const manuscript = getAmount(v, 'manuscript');
      const incomeTax = getAmount(v, 'income-tax');
      const residentTax = getAmount(v, 'resident-tax');
      const wci = getAmount(v, 'wci');
      const totalTax = manuscript + incomeTax + residentTax + wci;

      // 실지급액
      const netAmount = getAmount(v, 'net-amount');
      const bookCost = getAmount(v, 'book-cost');
      
      // 중복 데이터 처리
      const prev = acc.filter((e: any) => e.name === name);
      if (prev.length) {
        const removedNextAcc = acc.filter((e: any) => e.name !== name);

        const nextTotalPay = prev[0].totalPay + totalPay;
        const excludeExpense = Math.floor(nextTotalPay * 0.835);
        const nextHeadcount = prev[0].headcount + headcount;

        const prevManuscript = prev[0].manuscript + manuscript;
        const nextManuscript = excludeExpense < 800_000 ? 0 : 
                              (excludeExpense < 1_340_000 ? 10640 : Math.floor(excludeExpense * 0.008 / 10) * 10);

        const nextIncomeTax = prev[0].incomeTax + incomeTax;
        const nextResidentTax = prev[0].residentTax + residentTax;
        const prevWci = prev[0].wci + wci;
        const nextWci = Math.floor(excludeExpense * 0.0033 / 10) * 10;
        const nextTotalTax = nextManuscript + nextIncomeTax + nextResidentTax + nextWci;
        const nextNetAmount = prev[0].netAmount + netAmount + prevManuscript - nextManuscript + prevWci - nextWci;

        removedNextAcc.push({
          name,
          totalPay: nextTotalPay,
          headcount: nextHeadcount,
          manuscript: nextManuscript,
          incomeTax: nextIncomeTax,
          residentTax: nextResidentTax,
          wci: nextWci,
          totalTax: nextTotalTax,
          netAmount: nextNetAmount,
          month: currMonth
        });
        acc = removedNextAcc;
      } else {
        acc.push({ 
          name, totalPay, headcount, manuscript, incomeTax, residentTax, wci, totalTax, netAmount, bookCost,
          month: currMonth 
        });
      }

      return acc;
    }, []);

    const res = await HttpUtil.requestApi({
      url: '/pay_slip',
      method: 'POST',
      type: 'data',
      params: {
        reqType: 'add',
        data: members
      }
    });

    if (res.msg) {
      window.alert(res.msg);
      return;
    }
    window.alert('저장되었습니다.');
  }

  return (
    <>
      <div className='contents'>
        <Header month={currMonth} months={months} onMonthChange={onMonthChange} />
        <Select options={[{ label: '전체', value: '' }, ...school]} onChange={onSchoolChange}
          value={selectedSchool} />
        <Button type='primary' onClick={onAddTeacherClick}>강사 추가</Button>
        <Button onClick={onCopyClick}>현재 데이터 복사</Button>
        <Button onClick={onSendSalaryClick}>지급명세서 저장</Button>
        <Checkbox checked={!showAll} className='show-all' onChange={onShowAllChange}>실지급액만 보기</Checkbox>
        <p className='total'>
          전체 &nbsp;{totalCount}명,
          총 지급계 &nbsp;₩{getValueWithComma(totalAmount)}
        </p>
        {member.length && <DataList member={member} onNameClick={onNameClick} showAll={showAll} handleTotalAmount={handleTotalAmount} />}
      </div>
      <Modal width={840} className='oreo' onOk={onOk} onCancel={onCancel} open={showModal}
        footer={modalFooter}>
        <Form form={form} className='oreo'>
          <h3>
            강사 {method === 'POST' ? '추가' : '정보'}
            <FormCheckbox name='isCodi' list={[{ name: '코디/안전관리자', value: 'codi' }]} />
            <span>(코디네이터, 안전관리자일 경우 체크)</span>
          </h3>
          <Divider />
          <FormSelect name='schoolName' label='학교 선택' list={school} />
          <Form.Item name='payMonth' label='지급월 선택' rules={[{ required: true }]}>
            <DatePicker picker='month' format='YYYY-MM' />
          </Form.Item>
          <FormInput label='생년월일' name='teacherId' placeholder='급여명세서용' />
          <Divider />
          <FormInput name='className' label='과목명' />
          <FormInput name='teacherName' label='강사명' />
          <FormInput name='commission4w' label='4주분 지급수수료' />
          <Divider />
          <FormInput name='headCount4w' label='수강인원(4주)' required={false} />
          <FormInput name='headCount11of12' label='수강인원(11/12)' required={false} />
          <FormInput name='headCount10of12' label='수강인원(10/12)' required={false} />
          <FormInput name='headCount7of8' label='수강인원(7/8)' required={false} />
          <FormInput name='headCount3w' label='수강인원(3주)' required={false} />
          <FormInput name='headCount5of8' label='수강인원(5/8)' required={false} />
          <FormInput name='headCount2w' label='수강인원(2주)' required={false} />
          <FormInput name='headCount3of8' label='수강인원(3/8)' required={false} />
          <FormInput name='headCount1w' label='수강인원(1주)' required={false} />
          <FormInput name='headCount2of3' label='수강인원(2/3)' required={false} />
          <FormInput name='headCount1of3' label='수강인원(1/3)' required={false} />
          <FormInput name='bookCost' label='재료비' required={false} />
        </Form>
      </Modal>
    </>
  );
}

export default Main;