import { Checkbox, Form } from 'antd';
import { ReactNode } from 'react';

type TFormCheckbox = {
  name: string,
  label?: ReactNode | string,
  list: { name: string, value: string }[],
}

const FormCheckbox = ({ name, label = '', list }: TFormCheckbox) => {
  return (
    <Form.Item name={name} label={label} className='checkbox'>
      <Checkbox.Group>
        {list.map(v => <Checkbox key={v.value} value={v.value}>{v.name}</Checkbox>)}
      </Checkbox.Group>
    </Form.Item>
  );
}

export default FormCheckbox;