import { ConfigProvider } from 'antd';
import { StyleProvider } from '@ant-design/cssinjs';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

const Root = () => {
  return (
    <ConfigProvider theme={{
      token: {
        colorPrimary: '#3C68B7',
        colorPrimaryHover: '#2F6AC2',
        borderRadius: 5,
        wireframe: true
      }
    }}>
      <StyleProvider hashPriority='high'>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </StyleProvider>
    </ConfigProvider>
  );
}

export default Root;