import { useEffect } from 'react';

type TMember = {
  no: number,
  className: string,
  schoolName: string,
  teacherName: string,
  commission4w: number,
  headCount4w: number,
  headCount11of12: number,
  headCount10of12: number,
  headCount7of8: number,
  headCount3w: number,
  headCount5of8: number,
  headCount2w: number,
  headCount3of8: number,
  headCount1w: number,
  headCount2of3: number,
  headCount1of3: number,
  bookCost: number,
  isCodi: number,
  targetMonth: string
}

type TDataList = {
  onNameClick: (no: number) => void,
  member: TMember[],
  showAll: boolean,
  handleTotalAmount: (v: number) => void
}

const DataList = ({ member, onNameClick, showAll, handleTotalAmount }: TDataList) => {
  useEffect(() => {
    const netAmountList = document.querySelectorAll('.total-pay');
    const totalAmount = Array.from(netAmountList).reduce((acc, v) => {
      acc += +v.innerHTML.replaceAll(',', '');
      return acc;
    }, 0);

    handleTotalAmount(totalAmount);
    // eslint-disable-next-line
  }, [member]);

  const getNumberWithComma = (origin: number) => {
    return origin.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  }

  const getOtherCommission = (stdVal: number, type: string) => {
    const stdVal4 = stdVal / 4;
    const stdVal8 = stdVal / 8;
    const stdVal12 = stdVal / 12;
    let result;
    
    switch (type) {
      case '1112':
        result = getNumberWithComma(Math.floor((stdVal12 * 11) / 10) * 10);
        break;
      case '1012':
        result = getNumberWithComma(Math.floor((stdVal12 * 10) / 10) * 10);
        break;
      case '3':
        result = getNumberWithComma(stdVal4 * 3);
        break;
      case '78':
        result = getNumberWithComma(Math.floor((stdVal8 * 7) / 10) * 10);
        break;
      case '58':
        result = getNumberWithComma(Math.floor((stdVal8 * 5) / 10) * 10);
        break;
      case '38':
        result = getNumberWithComma(Math.floor((stdVal8 * 3) / 10) * 10);
        break;
      case '1':
        result = getNumberWithComma(Math.floor((stdVal4 * 1) / 10) * 10);
        break;
      default: // 1/3
        result = getNumberWithComma((Math.floor((stdVal / 3) / 10) * 10) * 3);
        break;
    }
    return result;
  }

  const getAmount = (stdVal: number, count: number, type: string) => {
    if (!count) {
      return '';
    }

    const stdVal3 = stdVal / 3;
    const stdVal4 = stdVal / 4;
    const stdVal8 = stdVal / 8;
    const stdVal12 = stdVal / 12;
    let result;
    
    switch (type) {
      case '1112':
        result = getNumberWithComma(Math.floor((stdVal12 * 11) / 10) * 10* count);
        break;
      case '1012':
        result = getNumberWithComma(Math.floor((stdVal12 * 10) / 10) * 10 * count);
        break;
      case '3':
        result = getNumberWithComma(Math.floor((stdVal4 * 3) / 10) * 10 * count);
        break;
      case '78':
        result = getNumberWithComma(Math.floor((stdVal8 * 7) / 10) * 10 * count);
        break;
      case '58':
        result = getNumberWithComma(Math.floor((stdVal8 * 5) / 10) * 10 * count);
        break;
      case '38':
        result = getNumberWithComma(Math.floor((stdVal8 * 3) / 10) * 10 * count);
        break;
      case '1':
        result = getNumberWithComma(Math.floor(stdVal4 / 10) * 10 * count);
        break;
      case '23': // 1/3
        result = getNumberWithComma(Math.floor(stdVal3 / 10) * 10* count);
        break;
      default: // 1/3
        result = getNumberWithComma(Math.floor(stdVal3 / 10) * 10 * count);
        break;
    }
    return result;
  }

  const getTotalPay = (v: TMember) => {
    const getAmountNumber = (stdVal: number, count: number, type: string) => {
      if (!count) {
        return 0;
      }
  
      const stdVal3 = stdVal / 3;
      const stdVal4 = stdVal / 4;
      const stdVal8 = stdVal / 8;
      const stdVal12 = stdVal / 12;
      let result;
      
      switch (type) {
        case '1112':
          result = Math.floor((stdVal12 * 11 / 3) / 10) * 10 * count;
          break;
        case '1012':
          result = Math.floor((stdVal12 * 10) / 10) * 10 * count;
          break;
        case '3':
          result = Math.floor((stdVal4 * 3) / 10) * 10 * count;
          break;
        case '78':
          result = Math.floor((stdVal8 * 7) / 10) * 10 * count;
          break;
        case '58':
          result = Math.floor((stdVal8 * 5) / 10) * 10 * count;
          break;
        case '38':
          result = Math.floor((stdVal8 * 3) / 10) * 10 * count;
          break;
        case '1':
          result = Math.floor(stdVal4 / 10) * 10 * count;
          break;
        case '23':
          result = Math.floor((stdVal3 * 2) / 10) * 10 * count;
          break;
        default: // 1/3
          result = Math.floor(stdVal3 / 10) * 10 * count;
          break;
      }
      return result;
    }

    const { commission4w, bookCost } = v;

    const p4w = v.headCount4w ? commission4w * v.headCount4w : 0;
    const p11of12 = getAmountNumber(commission4w, v.headCount11of12, '1112');
    const p10of12 = getAmountNumber(commission4w, v.headCount10of12, '1012');
    const p7of8 = getAmountNumber(commission4w, v.headCount7of8, '78');
    const p3w = v.headCount3w ? (Math.floor(commission4w * 0.75 / 10) * 10) * v.headCount3w : 0;
    const p5of8 = getAmountNumber(commission4w, v.headCount5of8, '58');
    const p2w = v.headCount2w ? (commission4w / 2) * v.headCount2w : 0;
    const p3of8 = getAmountNumber(commission4w, v.headCount3of8, '38');
    const p1w = getAmountNumber(commission4w, v.headCount1w, '1');
    const p2of3 = getAmountNumber(v.commission4w, v.headCount2of3, '23');
    const p1of3 = getAmountNumber(v.commission4w, v.headCount1of3, '13');
    
    const totalPay = v.isCodi ? commission4w : 
                          (p4w + p11of12 + p10of12 + p7of8 + p3w + p5of8 + p2w + p3of8 + p1w + p2of3 + p1of3);
    const excludeExpense = Math.floor(totalPay * 0.835);
    const wci = Math.floor(excludeExpense * 0.0033 / 10) * 10;
    const incomeTax = Math.floor(totalPay * 0.03 / 10) * 10;
    const residentTax = Math.floor(incomeTax * 0.1 / 10) * 10;
    const manuscript = excludeExpense < 800_000 ? 0 : 
                      (excludeExpense < 1_340_000 ? 10640 : Math.floor(excludeExpense * 0.008 / 10) * 10);
    const netAmount = totalPay - manuscript - wci - incomeTax - residentTax;

    return { 
      totalPay: getNumberWithComma(bookCost + totalPay),
      excludeExpense: getNumberWithComma(excludeExpense),
      manuscript: getNumberWithComma(manuscript),
      wci: getNumberWithComma(wci),
      incomeTax: getNumberWithComma(incomeTax),
      residentTax: getNumberWithComma(residentTax),
      netAmount: getNumberWithComma(bookCost + (Math.floor(netAmount / 10) * 10))
    }
  }

  return (
    <table>
      <thead>
        <tr>
          {/* <th rowSpan={2}>순번</th> */}
          <th rowSpan={2}>근무처</th>
          <th rowSpan={2}>과목명</th>
          <th rowSpan={2}>강사명</th>
          <th colSpan={11} className={`hidable ${showAll ? '' : 'hide'}`}>지급수수료</th>
          <th colSpan={11} className={`hidable ${showAll ? '' : 'hide'}`}>수강인원</th>
          <th colSpan={11} className={`hidable ${showAll ? '' : 'hide'}`}>지급액</th>
          <th rowSpan={2}>재료비</th>
          <th rowSpan={2}>지급계</th>
          <th rowSpan={2}>경비율<br />제외</th>
          <th rowSpan={2}>특고료</th>
          <th rowSpan={2}>산재<br />보험료</th>
          <th colSpan={2}>공제액</th>
          <th rowSpan={2}>실지급액</th>
        </tr>
        <tr>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>4주분</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>11/12</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>10/12</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>7/8</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>3주분</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>5/8</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>2주분</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>3/8</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>1주분</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>2/3</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>1/3</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>4주분</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>11/12</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>10/12</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>7/8</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>3주분</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>5/8</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>2주분</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>3/8</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>1주분</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>2/3</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>1/3</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>4주분</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>11/12</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>10/12</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>7/8</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>3주분</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>5/8</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>2주분</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>3/8</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>1주분</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>2/3</th>
          <th className={`hidable ${showAll ? '' : 'hide'}`}>1/3</th>
          <th>소득세</th>
          <th>주민세</th>
        </tr>
      </thead>
      <tbody>
        {member.map((v, i) => {
          const totalPaySet = getTotalPay(v);
          return (
            <tr key={i} className='each-member'>
              <td className='text'>{v.schoolName}초</td>
              <td className='text'>{v.className}</td>
              <td className='text'>
                <span onClick={() => onNameClick(v.no)} className='name'>{v.teacherName}</span>
              </td>
              <td className='book-cost'>{getNumberWithComma(v.bookCost)}</td>
              <td className={`std ${showAll ? '' : 'hide'}`}>{getNumberWithComma(v.commission4w)}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{v.isCodi ? '' : getOtherCommission(v.commission4w, '1112')}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{v.isCodi ? '' : getOtherCommission(v.commission4w, '1012')}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{v.isCodi ? '' : getOtherCommission(v.commission4w, '78')}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{v.isCodi ? '' : getNumberWithComma(Math.floor(v.commission4w * 0.75 / 10) * 10)}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{v.isCodi ? '' : getOtherCommission(v.commission4w, '58')}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{v.isCodi ? '' : getNumberWithComma(v.commission4w / 2)}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{v.isCodi ? '' : getOtherCommission(v.commission4w, '38')}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{v.isCodi ? '' : getOtherCommission(v.commission4w, '1')}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{v.isCodi ? '' : getNumberWithComma(Math.floor((v.commission4w / 3 * 2) / 10) * 10)}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{v.isCodi ? '' : getNumberWithComma(Math.floor((v.commission4w / 3) / 10) * 10)}</td>
              <td className={`text headcount ${showAll ? '' : 'hide'}`}>{v.headCount4w || ''}</td>
              <td className={`text headcount ${showAll ? '' : 'hide'}`}>{v.headCount11of12 || ''}</td>
              <td className={`text headcount ${showAll ? '' : 'hide'}`}>{v.headCount10of12 || ''}</td>
              <td className={`text headcount ${showAll ? '' : 'hide'}`}>{v.headCount7of8 || ''}</td>
              <td className={`text headcount ${showAll ? '' : 'hide'}`}>{v.headCount3w || ''}</td>
              <td className={`text headcount ${showAll ? '' : 'hide'}`}>{v.headCount5of8 || ''}</td>
              <td className={`text headcount ${showAll ? '' : 'hide'}`}>{v.headCount2w || ''}</td>
              <td className={`text headcount ${showAll ? '' : 'hide'}`}>{v.headCount3of8 || ''}</td>
              <td className={`text headcount ${showAll ? '' : 'hide'}`}>{v.headCount1w || ''}</td>
              <td className={`text headcount ${showAll ? '' : 'hide'}`}>{v.headCount2of3 || ''}</td>
              <td className={`text headcount ${showAll ? '' : 'hide'}`}>{v.headCount1of3 || ''}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{v.headCount4w ? getNumberWithComma(v.commission4w * v.headCount4w) : ''}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{getAmount(v.commission4w, v.headCount11of12, '1112')}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{getAmount(v.commission4w, v.headCount10of12, '1012')}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{getAmount(v.commission4w, v.headCount7of8, '78')}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{v.headCount3w ? getNumberWithComma((Math.floor(v.commission4w * 0.75 / 10) * 10) * v.headCount3w) : ''}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{getAmount(v.commission4w, v.headCount5of8, '58')}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{v.headCount2w ? getNumberWithComma((v.commission4w / 2) * v.headCount2w) : ''}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{getAmount(v.commission4w, v.headCount3of8, '38')}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{getAmount(v.commission4w, v.headCount1w, '1')}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{getAmount(v.commission4w, v.headCount2of3, '23')}</td>
              <td className={`readonly ${showAll ? '' : 'hide'}`}>{getAmount(v.commission4w, v.headCount1of3, '13')}</td>
              <td className='total-pay'>{totalPaySet.totalPay}</td>
              <td className='exclude-expense'>{totalPaySet.excludeExpense}</td>
              <td className='manuscript'>{totalPaySet.manuscript}</td>
              <td className='wci'>{totalPaySet.wci}</td>
              <td className='income-tax'>{totalPaySet.incomeTax}</td>
              <td className='resident-tax'>{totalPaySet.residentTax}</td>
              <td className='net-amount'>{totalPaySet.netAmount}</td>
            </tr>
            );
          }
        )}
      </tbody>
    </table>
  );
};

export default DataList;