import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FormInput, FormPassword, FormSubmit } from '@components';
import { HttpUtil } from '@utils';
import '@css/login.scss';

const Login = () => {
  const [form] = useForm();

  const navigate = useNavigate();

  useEffect(() => {
    const storage = sessionStorage.getItem('isLogin');
    const isLogin = storage === null ? false : JSON.parse(storage).data;
    if (isLogin) {
      navigate('/', { replace: true });
    }
  }, [navigate]);

  const onFinish = async (values: any) => {
    const res = await HttpUtil.requestApi({
      url: '/auth',
      method: 'POST',
      params: {
        reqType: 'login',
        userId: values.userId,
        userPw: values.userPw
      }
    });
    if (res.msg) {
      window.alert(res.msg);
      return;
    }
    sessionStorage.setItem('isLogin', JSON.stringify({ data: true }));
    navigate('/', { replace: true });
  }

  return (
    <div className='container-login'>
      <Form form={form} onFinish={onFinish} className='oreo'>
        <FormInput label='아이디' name='userId' />
        <FormPassword label='비밀번호' name='userPw' />
        <FormSubmit name='로그인' />
      </Form>
    </div>
  );
}

export default Login;